import { toggleModal, renderModal, setPuzzleTime } from "./puzzle-modal";
import { setModalShareText } from "./share";
import { sendTrackingLabel } from "./tracking";

export default async (rootElement) => {
  if(!rootElement) {
    return;
  }

  await import("/js/provider/puzzler-v4/pml.js");

  const puzzleUrl = rootElement.dataset.puzzleUrl;
  const puzzleShareLink = rootElement.dataset.shareLink;
  const modal = rootElement.querySelector('[data-role="puzzle-modal"]');
  const time = rootElement.querySelector('[data-role="puzzle-time"]');
  const data = await fetch(`https://components.news.sky.com/${puzzleUrl}`);
  const puzzle = await data.json();

  const trackedEvents = [
    "GAME_COMPLETED",
    "GAME_HINT_MENU_SHOWN",
    "GAME_HINT_USED",
    "GAME_INCORRECT_COMPLETION",
    "GAME_RENDERED",
    "GAME_RESET",
    "GAME_STARTED",
    "GAME_TOGGLE_OPTION",
  ]

  renderModal(modal);

  window.pml.startGame("puzzle-root", {
      "scriptPath": "/js/provider/puzzler-v4/",
      "autoViewport": true,
      "useRemoteSDK": false,
      puzzle,
      eventCallback: (name, event) => {

        if (trackedEvents.includes(name)) {
          sendTrackingLabel(`puzzles/${puzzle.slug}/${name}`.toLowerCase());
        }

        if (name === "GAME_COMPLETED") {
          if (event.successful === true) {
            setPuzzleTime(time, event.timer_value);
            setModalShareText(modal, event.timer_value, puzzleShareLink);
            toggleModal(modal, 'open');
          }
          // prevents default puzzler modal appearing
          return true;
        }
    }
  });
}