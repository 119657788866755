import { copyLink } from "./share";

export function toggleModal(modal, action = 'open') {
  modal.setAttribute('data-show', action === 'open')
  modal.firstElementChild.focus();
}

export function setModalPage(modal, page) {
  modal.style.setProperty('--modal-page', page);
}

export function renderModal(modal) {
  const shareButton = modal.querySelector('[data-role="modal-share"]');
  const closeButton = modal.querySelector('[data-role="modal-close"]');
  const shareCopyLink = modal.querySelector('[data-role="share-copy-link"]');

  shareButton.onclick = () => {
    setModalPage(modal, 2);
  }

  closeButton.onclick = () => {
    toggleModal(modal, 'close');
    setModalPage(modal, 1);
  }

  shareCopyLink.onclick = () => {
    copyLink(shareCopyLink, shareCopyLink.dataset.canonicalLink);
  }
}

export function formatTime(time) {
  const units = ['seconds', 'minutes', 'hours'];
  return time.replace(/ /g, '').split(':').reverse().map((t, index) => {
    return (`${parseInt(t, 10)} ${units[index]}`)
      .replace(/^1 hours/, '1 hour')
      .replace(/^1 minutes/, '1 minute')
      .replace(/^1 seconds/, '1 second')
      .replace(/^0 hours/,  '')
      .replace(/^0 minutes/, '')
      .replace(/^0 seconds/, '')
  }).reverse().join(' ');
}

export function setPuzzleTime(el, time) {
  el.innerHTML = `in <strong>${formatTime(time)}</strong>`;
}