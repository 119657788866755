import { formatTime } from "./puzzle-modal";

export function copyLink(button, text){
  try {
    navigator.clipboard.writeText(text).then(() => {
      button.dataset.copied = true;
      setTimeout(() => { button.dataset.copied = false }, 3000)
    });
  } catch (error) {
    console.error('This device does not support writing to the clipboard');
  }
}

export function setModalShareText(modal, time, url) {

  const shareText = `I completed this in ${formatTime(time)}, can you beat me?`;

  modal.querySelectorAll('[data-url]').forEach((link) => {
    link.href = link.getAttribute('data-url')
      .replace('${url}', url)
      .replace('${text}', shareText)
  })
}