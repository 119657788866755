export const sendTrackingLabel = (label) => {
  if (!window.skyTags) {
    return;
  }
  const params = {
    type: 'click',
    timestamp: new Date().valueOf(),
    element: {
      custom: {
        linkDetails: {
          other: 'click',
          text: label
        },
      },
      locator: {
        logical: [label]
      }
    }
  };
  window.skyTags.queue.push(['event', params], ['track']);
};
